@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Maax';
  src: url('../public/fonts/Maax-MediumItalic.woff2') format('woff2'),
    url('../public/fonts/Maax-MediumItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Maax';
  src: url('../public/fonts/Maax-Italic.woff2') format('woff2'),
    url('../public/fonts/Maax-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Maax';
  src: url('../public/fonts/Maax-Medium.woff2') format('woff2'),
    url('../public/fonts/Maax-Medium.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Maax';
  src: url('../public/fonts/Maax-Regular.woff2') format('woff2'),
    url('../public/fonts/Maax-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@layer base {
  :root {
    --theme-fontSize: 18px;
    --theme-fontFamily: Maax;
  }

  html {
    @apply bg-secondary text-primary;
    font-size: var(--theme-fontSize);
    font-family: var(--theme-fontFamily), sans-serif;
    line-height: 1.4rem;
    height: 100%;
  }

  body {
    overscroll-behavior: none;
    height: 100%;
  }

  #root {
    height: 100%;
  }
}

div.triangle {
  width: 0;
  height: 0;
  border-left: 1.389rem solid transparent;
  border-right: 1.389rem solid transparent;
  border-bottom: 2.222rem solid var(--theme-primary);
  color: var(--theme-secondary);
}

div.triangle.big {
  border-left: 2.0845rem solid transparent;
  border-right: 2.0845rem solid transparent;
  border-bottom: 3.333rem solid var(--theme-primary);
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 0.4rem;
  background: var(--theme-primary);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 0.5rem;
  height: 1.5rem;
  background: var(--theme-primary);
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 0.5rem;
  height: 1.5rem;
  border-radius: 0;
  border: none;
  background: var(--theme-primary);
  cursor: pointer;
}
